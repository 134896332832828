import React, {Dispatch, SetStateAction} from 'react';
import BankTransferFormPersonal from "../../../../../components/BankTransferFormPersonal/BankTransferFormPersonal";

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
    setTransferData: Dispatch<SetStateAction<any>>
}

const BankTransferStepOne = (props: PropsType) => {

    return (
        <>
            <BankTransferFormPersonal
                setTransferData={props.setTransferData}
                setCurrent={props.setCurrent}
            />
        </>

    );

};

export default BankTransferStepOne;
