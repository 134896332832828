import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./ToAnLuxPayTransferForm.module.css";
import {Popover} from "antd";
import info from "../../assets/img/personalAccount/information.svg";
import CustomDropdown from "../ui/CustomDropdown/CustomDropdown";
import MyInput from "../ui/MyInput/MyInput";
import classnames from "classnames";
import MyBtn from "../ui/MyBtn/MyBtn";
import {Persist} from "formik-persist";
import {motion} from "framer-motion";
import * as yup from "yup";
import CustomDropdownForAccounts from "../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import {useAccountsForSelect} from "../../hooks/useAccountsForSelect";
import invoice from "../../assets/img/personalAccount/invoice.svg"
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Plus} from "../../assets/img/personalAccount/plus.svg";
import attachedDoc from "../../assets/img/personalAccount/document.svg";
import delete_document from "../../assets/img/personalAccount/delete_doc.svg";
import {useAppSelector} from "../../hooks/redux";

import {
    CheckAvailableAccountsByEmail,
    CheckAvaliableAccountsByPhone, CheckAvaliableCurrencyByAccount,
    CurrencyHelper,
    ResetFieldByTransferMethod
} from "./Helpers";
import CustomModal from "../ui/CustomModal/CustomModal";

const validationSchema = yup.object({
    accNumberFrom: yup.string().required('Required field'),
    accNumberTo: yup.string().required('Required field').max(40, 'max 40 symbols'),
    transferMethod: yup.string().required('Required field'),
    phoneNumber: yup.string().test({message: 'Required field',
        test: function (value) {
            const { transferMethod } = this.parent;
            if (transferMethod === 'Phone number') return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    email: yup.string().test({message: 'Required field',
        test: function (value) {
            const { transferMethod } = this.parent;
            if (transferMethod === 'Email') return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Invalid format")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    if (item === '0') {
                        return true
                    }
                })

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols')
})

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>
    setTransferData?: Dispatch<SetStateAction<any>>
    id?: string | number;
}

const ToAnLuxPayTransferForm = (props: PropsType) => {

    const [currency, setCurrency] = useState('')
    const [isMobile, setIsMobile] = useState(false)
    const [isAccountWrong, setIsAccountWrong] = useState<boolean | null>(false)
    const [isAvailableAccountExists, setIsAvailableAccountExists] = useState<boolean | null>(true)
    const [availableBeneficiaryAccounts, setAvailableBeneficiaryAccounts] = useState([])

    const accountsList = useAccountsForSelect().filter((account: any) => account.number !== "ALL")
    const token = useAppSelector(state => state.userReducer.userToken)

    const transferMethods = [
        'Account number',
        'Email',
        'Phone number',
    ]

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth])

    const handleReset = () => {
        setAvailableBeneficiaryAccounts([])
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <Formik initialValues={{
                accNumberFrom: '',
                accNumberTo: '',
                email: '',
                phoneNumber: '',
                emailLanguage: 'EN',
                reference: '',
                templateName: '',
                amount: '',
                transferMethod: 'Account number',
                type: 'internal',
                invoice: '',
                purpose: '',
            }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {resetForm}) => {

                        let tempTransferMethod: string|null = ''

                        if (values.transferMethod === 'Phone number') {
                            tempTransferMethod = 'phone'
                        }

                        if (values.transferMethod === 'Email') {
                            tempTransferMethod = 'email'
                        }

                        if (values.transferMethod === 'Account number') {
                            tempTransferMethod = 'account'
                        }

                        const tempData = {
                            ...values,
                            transferCurrency: currency,
                            transferMethod: tempTransferMethod
                        }
                        {
                            props.setTransferData && props.setTransferData(tempData)
                        }
                        {
                            props.setCurrent && props.setCurrent(1)
                        }

                        resetForm({
                            // @ts-ignore
                            values: ''
                        })

                    }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <CustomModal type="error" isNotify isModalOpen={isAccountWrong} setIsModalOpen={setIsAccountWrong}>
                            <div className={s.modal_content}>
                                <div className={s.popup_subtitle}>
                                    <span className={s.error}>Failed!</span> The transfer cannot be made as currency of
                                    the payer account doesn't equal to currency of the recipient account.
                                </div>
                                <div className={s.popup_button_block}>
                                    <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => {setIsAccountWrong(false)}}/>
                                </div>
                            </div>
                        </CustomModal>

                        <CustomModal type="error" isNotify isModalOpen={!isAvailableAccountExists} setIsModalOpen={setIsAvailableAccountExists}>
                            <div className={s.modal_content}>
                                <div className={s.popup_subtitle}>
                                    <span className={s.error}>Failed!</span> The transfer cannot be made to the specified&nbsp;
                                    {values.transferMethod.toLowerCase()} due to the lack of an account with the recipient.
                                </div>
                                <div className={s.popup_button_block}>
                                    <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => {setIsAvailableAccountExists(true)}}/>
                                </div>
                            </div>
                        </CustomModal>

                        <div className={s.row}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Payer account
                                    <Popover content={"Please select the account you wish to make a transfer from."}
                                             color={'#EEEFEF'}
                                             overlayInnerStyle={{
                                                 fontFamily: 'Inter',
                                                 width: '280px',
                                                 padding: '13px 16px'
                                             }}
                                    >
                                        <img src={info} alt=""/>
                                    </Popover>
                                </div>
                                <CustomDropdownForAccounts
                                    items={accountsList}
                                    placeholder={"Please select the account"}
                                    id="accNumberFrom"
                                    name="accNumberFrom"
                                    isError={errors.accNumberFrom}
                                    touched={touched.accNumberFrom}
                                    setCurrency={setCurrency}
                                />
                                {errors.accNumberFrom &&
                                    <div className={s.error_message}>{errors.accNumberFrom}</div>}
                            </div>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Transfer Method
                                    <Popover content={"Choose one of the recipient’s identifiers: their Iberbanco" +
                                        " account, email address or phone number."}
                                             color={'#EEEFEF'}
                                             overlayInnerStyle={{
                                                 fontFamily: 'Inter',
                                                 width: '280px',
                                                 padding: '13px 16px'
                                             }}
                                    >
                                        <img src={info} alt=""/>
                                    </Popover>
                                </div>
                                <CustomDropdown
                                    items={transferMethods}
                                    id="transferMethod"
                                    name="transferMethod"
                                    isError={errors.transferMethod}
                                    touched={touched.transferMethod}
                                />
                            </div>
                        </div>

                        <div className={s.block}>
                            <div className={s.title}>
                                Outgoing Payment Details
                            </div>

                            <div className={s.row}>

                                <div className={s.input_block}>
                                    {values.transferMethod === 'Account number'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Account number
                                                <Popover
                                                    content={<span>
                                                        Account number in the format <br/>
                                                        <span className={s.color_popover}>CAIBNCXXXXXXXXXXXXXXX.</span>
                                                    </span>}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"accNumberTo"}
                                                     name={"accNumberTo"}
                                                     touched={touched.accNumberTo}
                                                     value={values.accNumberTo}
                                                     onChange={handleChange}
                                                     isError={errors.accNumberTo}
                                                     placeholder={"Enter recipient's account number"}
                                            />
                                            {errors.accNumberTo &&
                                                <div className={s.error_message}>{errors.accNumberTo}</div>}
                                        </div>
                                    }

                                    {values.transferMethod === 'Email'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                *Email
                                            </div>
                                            <MyInput id={"email"}
                                                     name={"email"}
                                                     touched={touched.email}
                                                     value={values.email}
                                                     onChange={handleChange}
                                                     isError={errors.email}
                                            />
                                        </div>
                                    }

                                    {values.transferMethod === 'Phone number'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * phone number
                                                <Popover
                                                    content={"Phone number in international format."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"phoneNumber"}
                                                     name={"phoneNumber"}
                                                     touched={touched.phoneNumber}
                                                     value={values.phoneNumber}
                                                     onChange={handleChange}
                                                     isError={errors.phoneNumber}
                                            />
                                        </div>
                                    }

                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        Purpose
                                    </div>
                                    <MyInput id={"purpose"}
                                             name={"purpose"}
                                             touched={touched.purpose}
                                             value={values.purpose}
                                             onChange={handleChange}
                                             isError={errors.purpose}
                                             placeholder={"Enter your purpose of payment"}
                                    />
                                </div>

                            </div>

                            <div className={classnames(s.row)}>
                                <div className={s.mobile_row}>
                                    <div className={classnames(s.input_block, s.short)}>
                                        <div className={s.input_label}>
                                            * Amount
                                            <Popover
                                                content={"Select a currency and enter the transfer amount.\n" +
                                                    "In the account balance in the selected currency is insufficient, the system " +
                                                    "will automatically offer you to top up using funds in a different currency " +
                                                    "you have on the account."}
                                                color={'#EEEFEF'}
                                                overlayInnerStyle={{
                                                    fontFamily: 'Manrope',
                                                    fontSize: '14px',
                                                    width: '280px',
                                                    padding: '13px 16px'
                                                }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <MyInput id={"amount"}
                                                 name={"amount"}
                                                 touched={touched.amount}
                                                 value={values.amount}
                                                 onChange={handleChange}
                                                 isError={errors.amount}
                                        />
                                        {errors.amount && touched.amount &&
                                            <div
                                                className={classnames(s.error_message, s.error_amount)}>{errors.amount}</div>}
                                    </div>

                                    <div className={s.currency_dropdown}>
                                        <div className={s.input_label}>
                                            Currency
                                        </div>
                                        <MyInput
                                            id="transferCurrency"
                                            name="transferCurrency"
                                            value={currency}
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                { values.transferMethod !== "Account number" && <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * account number
                                    </div>
                                    <CustomDropdownForAccounts
                                        items={availableBeneficiaryAccounts}
                                        placeholder={"Please select the account"}
                                        id="accNumberTo"
                                        name="accNumberTo"
                                        isError={errors.accNumberTo}
                                        touched={touched.accNumberTo}
                                        setCurrency={setCurrency}
                                    />
                                </div>}
                            </div>
                        </div>

                        {!props.id && <div className={s.block}>
                            <div className={s.title}>
                                Save the Transfer Details as a Template
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        template Name
                                        <Popover
                                            content={"In order to save this transfer as a template, please enter the " +
                                                "template name."}
                                            color={'#EEEFEF'}
                                            overlayInnerStyle={{
                                                fontFamily: 'Inter',
                                                width: '280px',
                                                padding: '13px 16px'
                                            }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <MyInput id={"templateName"}
                                             name={"templateName"}
                                             touched={touched.templateName}
                                             value={values.templateName}
                                             onChange={handleChange}
                                             isError={errors.templateName}
                                    />
                                    {errors.templateName &&
                                        <div className={s.error_message}>{errors.templateName}</div>}
                                </div>

                                <div className={s.input_block}>

                                </div>
                            </div>
                        </div>}

                        {
                            !props.id &&
                            <div className={s.add_dock_block}>
                                <div className={classnames(s.row_doc, s.flex_start)}>
                                    <div className={s.invoice_img}>
                                        <img src={invoice} alt=""/>
                                    </div>
                                    <div>
                                        <p className={s.invoice_title}>Add an invoice (optional)</p>
                                        <p className={s.invoice_desc}>Appropriate format: JPEG, JPG, PNG, TXT, PDF.</p>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.button_wrapper}>
                                        <TransparentButton
                                            title={"Add file"}
                                            isPersonalAccountBtn
                                            small
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('hiddenInvoice').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept=".png, .jpg, .jpeg, .pdf, .txt"
                                               id={"hiddenInvoice"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setFieldValue("invoice", event.currentTarget.files[0]);
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            values.invoice && <img src={attachedDoc} alt=""/>
                                        }
                                        {
                                            // @ts-ignore
                                            <span className={s.invoice_doc_name}>{values?.invoice?.name}</span>
                                        }
                                        {
                                            // @ts-ignore
                                            values?.invoice?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("hiddenInvoice").value = ""
                                                setFieldValue("invoice", "")
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={s.continue_block}>
                            <div className={s.required}>
                                <div>
                                    * required fields.
                                </div>
                                <div>
                                    ** one of the fields is required.
                                </div>
                            </div>
                            {!props.id && <div className={s.button_wrapper}>
                                <MyBtn title={"Continue"} large type={"submit"} isPersonalAccountBtn/>
                            </div>}
                        </div>

                        <Persist name="iberbanco-transfer-form"/>

                        <CurrencyHelper
                            accountsList={accountsList}
                            accNumberFrom={values.accNumberFrom}
                            setCurrency={setCurrency}
                        />

                        <CheckAvailableAccountsByEmail
                            name={"accNumberTo"}
                            email={values.email}
                            token={token}
                            currency={currency}
                            setAvailableBeneficiaryAccounts={setAvailableBeneficiaryAccounts}
                            setIsAvailableAccountExists={setIsAvailableAccountExists}
                        />

                        <CheckAvaliableAccountsByPhone
                            name={"accNumberTo"}
                            phone={values.phoneNumber}
                            token={token}
                            currency={currency}
                            setAvailableBeneficiaryAccounts={setAvailableBeneficiaryAccounts}
                            setIsAvailableAccountExists={setIsAvailableAccountExists}
                        />

                        <CheckAvaliableCurrencyByAccount
                            name={"accNumberTo"}
                            account={values.accNumberTo}
                            token={token}
                            currency={currency}
                            setIsAccountWrong={setIsAccountWrong}
                        />

                        <ResetFieldByTransferMethod
                            name={"email"}
                            handleReset={() => {}}
                        />
                        <ResetFieldByTransferMethod
                            name={"phoneNumber"}
                            handleReset={() => {}}
                        />
                        <ResetFieldByTransferMethod
                            name={"accNumberTo"}
                            handleReset={handleReset}
                        />

                    </form>
                )}
            </Formik>
        </motion.div>
    );
};

export default ToAnLuxPayTransferForm;
