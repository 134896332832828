import React, {useEffect, useState} from 'react';
import styles from './Footer.module.css'
import {Link, useLocation} from "react-router-dom";
import {
    ABOUT,
    BUSINESS_ACCOUNT,
    CAREERS, COMPLAINTS,
    CONTACT_US,
    CONTACT_US_FOR_AGENTS,
    COOKIE_POLICY,
    FAQ, FRAUD_AND_SCAM_ALERT,
    PERSONAL_ACCOUNT, AGENT_PROGRAM,
    PRICING, PRIVACY_POLICY, TERMS_AND_CONDITIONS
} from "../../Constants/RoutesConstants";
import {handlerScrollUp} from '../../helpers/ScrollTo';

const Footer = () => {

    const location = useLocation()

    const [isFooterShow, setIsFooterShow] = useState(true)

    useEffect(() => {
        if (
            location.pathname.includes("personal_account") ||
            location.pathname.includes("login") ||
            location.pathname.includes("sign_up") ||
            location.pathname.includes("kyc_success") ||
            location.pathname.includes("kyc_declined") ||
            location.pathname.includes("kyc_failure")
        ) {
            setIsFooterShow(false)
        } else {
            setIsFooterShow(true)
        }
    }, [location.pathname])

    return (
        <>
            {
                isFooterShow &&
                <footer className={styles.footer_wrapper}>
                    <Link to="/">
                        <div className={styles.logo}></div>
                    </Link>
                    <div className={styles.copyright_mobile}>
                        All rights reserved © 2023 <br/>
                        Lux International Payment System, UAB <br/>
                        License No. 81. <a
                        href={"https://www.lb.lt/en/sfi-financial-market-participants/uab-lux-international-payment-system"}
                        className={styles.license_link}>Bank of Lithuania</a>
                    </div>
                    <div className={styles.links_wrapper}>
                        <div className={styles.links_block}>
                            <div className={styles.links_block_title}>Company</div>
                            <Link className={styles.footer_link} to={ABOUT} onClick={handlerScrollUp}>About</Link>
                            <Link className={styles.footer_link} to={CAREERS} onClick={handlerScrollUp}>Careers</Link>
                            <Link className={styles.footer_link} to={FAQ} onClick={handlerScrollUp}>FAQ</Link>
                            <Link className={styles.footer_link} to={CONTACT_US} onClick={handlerScrollUp}>Contact
                                Us</Link>
                            <Link className={styles.footer_link} to={CONTACT_US_FOR_AGENTS} onClick={handlerScrollUp}>Contact
                                for agents</Link>

                        </div>
                        <div className={styles.links_block}>
                            <div className={styles.links_block_title}>Services</div>
                            <Link className={styles.footer_link} to={PERSONAL_ACCOUNT} onClick={handlerScrollUp}>Personal
                                account</Link>
                            <Link className={styles.footer_link} to={BUSINESS_ACCOUNT} onClick={handlerScrollUp}>Business
                                account</Link>
                            <Link className={styles.footer_link} to={PRICING} onClick={handlerScrollUp}>Pricing</Link>
                            <Link className={styles.footer_link} to={AGENT_PROGRAM} onClick={handlerScrollUp}>Agent Program</Link>
                        </div>
                        <div className={styles.links_block}>
                            <div className={styles.links_block_title}>Legal</div>
                            <Link className={styles.footer_link} to={TERMS_AND_CONDITIONS} onClick={handlerScrollUp}>Terms
                                & Conditions</Link>
                            <Link className={styles.footer_link} to={PRIVACY_POLICY} onClick={handlerScrollUp}>Privacy
                                Policy</Link>
                            <Link className={styles.footer_link} to={COOKIE_POLICY} onClick={handlerScrollUp}>Cookie
                                Policy</Link>
                            <Link className={styles.footer_link} to={COMPLAINTS}
                                  onClick={handlerScrollUp}>Complaints</Link>
                            <Link className={styles.footer_link} to={FRAUD_AND_SCAM_ALERT}
                                  onClick={handlerScrollUp}>Fraud and Scam Alert</Link>
                        </div>
                        <div>
                            <div className={styles.links_block_title_follow}>Follow Us</div>
                            <div className={styles.social_networks}>
                                <Link target="_blank" to={"https://www.linkedin.com/company/luxpay-fintech"}>
                                    <div className={styles.linkedin}></div>
                                </Link>
                                <div className={`${styles.facebook} ${styles.inactive}`}></div>
                                <div className={`${styles.twitter} ${styles.inactive}`}></div>
                            </div>
                            <div className={styles.button_block_mobile}>
                                <Link to={"/sign_up"}>
                                    <button className={styles.get_started_btn}>Get started</button>
                                </Link>
                            </div>
                            <div className={styles.copyright}>
                                All rights reserved © 2024 <br/>
                                Lux International Payment System, UAB <br/>
                                License No. 81. <a
                                href={"https://www.lb.lt/en/sfi-financial-market-participants/uab-lux-international-payment-system"}
                                className={styles.license_link}>Bank of Lithuania</a>
                            </div>
                        </div>
                    </div>
                </footer>
            }
        </>

    );
};

export default Footer;
