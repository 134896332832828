import React, {useEffect, useState} from 'react';
import s from "../Security.module.css";
import {Switch} from "antd";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import {ReactComponent as Check} from "../../../../assets/img/personalAccount/checked.svg";
import {ReactComponent as Cross} from "../../../../assets/img/personalAccount/cross.svg";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {sendNewWithdrawalSettings} from "../../../../store/reducers/ActionCreators";
import CustomModal from "../../../../components/ui/CustomModal/CustomModal";
import iconCheck from "../../../../assets/img/personalAccount/tick-circle.svg";
import styles from "../../../../styles/styles.module.css"

type propsType = {
    isMobile: boolean
}

const WithdrawalSettings = (props: propsType) => {

    const dispatch = useAppDispatch();
    const verifyWithdrawal: boolean | undefined = useAppSelector(state => state.userReducer.user.flags.verifyWithdrawal)
    const blockWithdrawal: boolean | undefined = useAppSelector(state => state.userReducer.user.flags.blockWithdrawal)
    const token: string = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)

    const [settings, setSettings] = useState<any>({verifyWithdrawal, blockWithdrawal})
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
    const [isSettingsSaveSuccessfully, setIsSettingsSaveSuccessfully] = useState<boolean | null>(null)

    useEffect(() => {
        if (blockWithdrawal === settings.blockWithdrawal && verifyWithdrawal === settings.verifyWithdrawal) {
            setIsButtonDisabled(true)
        }
    }, [blockWithdrawal, settings, verifyWithdrawal]);

    const sendNewSettings = () => {
        dispatch(sendNewWithdrawalSettings(token, settings, accountId))
            .then((res) => {
                if (res.data && res.data === "Updated") {
                    setIsSettingsSaveSuccessfully(true)
                } else {
                    setIsSettingsSaveSuccessfully(false)
                }
            })
    }

    const closeModal = () => {
        setIsSettingsSaveSuccessfully(false)
    }

    return (
        <div>

            <CustomModal isModalOpen={isSettingsSaveSuccessfully} type={"confirm"}>
                <div className={styles.modal_content}>
                    <img src={iconCheck} alt="" className={styles.popup_icon}/>
                    <div className={styles.popup_title}>
                        Success!
                    </div>
                    <div className={styles.popup_subtitle}>
                        Your changes have been saved.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn isPersonalAccountBtn title={"Ok"} onClick={() => closeModal()}/>
                    </div>
                </div>
            </CustomModal>

            <div className={s.autentication_list_block}>
                <div className={s.autentication_item}>
                    <div className={s.row}>
                            <span
                                className={s.autentication_title}>Verify IP addresses before confirming withdrawals</span>
                        <Switch
                            rootClassName={s.my_root_switch}
                            className={s.my_switch}
                            checked={settings.verifyWithdrawal}
                            onChange={() => {
                                setSettings({...settings, verifyWithdrawal: !settings.verifyWithdrawal});
                                if (verifyWithdrawal === settings.verifyWithdrawal) {
                                    setIsButtonDisabled(false)
                                }
                            }}
                            checkedChildren={<Check style={settings.verifyWithdrawal ? {
                                position: 'relative',
                                left: 19,
                                top: props.isMobile ? 5 : 2
                            } : {position: 'relative', left: 19, top: 100}}/>}
                            unCheckedChildren={<Cross style={settings.verifyWithdrawal ? {
                                position: 'relative',
                                left: 19.5,
                                top: 100
                            } : {
                                position: 'relative',
                                right: props.isMobile ? 19 : 20,
                                top: props.isMobile ? 12 : -1
                            }}/>}

                        />
                    </div>
                    <div className={s.autentication_item_description}>
                        If a withdrawal is requested from a new IP address, you will receive an email to verify and
                        confirm the operation. The "distrust" period for an IP address
                        lasts 24 hours. If the withdrawal is requested 24 hours or more after the change of IP
                        address, no additional verification with the letter is required.

                    </div>
                </div>

                <div className={s.autentication_item}>
                    <div className={s.row}>
                            <span className={s.autentication_title}>Block withdrawals for 24 hours when using a new IP
                                address</span>
                        <Switch
                            rootClassName={s.my_root_switch}
                            className={s.my_switch}
                            checked={settings.blockWithdrawal}
                            onChange={() => {
                                setSettings({...settings, blockWithdrawal: !settings.blockWithdrawal});
                                if (blockWithdrawal === settings.blockWithdrawal) {
                                    setIsButtonDisabled(false)
                                }
                            }}
                            checkedChildren={<Check style={settings.blockWithdrawal ? {
                                position: 'relative',
                                left: 17,
                                top: props.isMobile ? 5 : 2
                            } : {position: 'relative', left: 19, top: 100}}/>}
                            unCheckedChildren={<Cross style={settings.blockWithdrawal ? {
                                position: 'relative',
                                left: 19,
                                top: 100
                            } : {
                                position: 'relative',
                                right: props.isMobile ? 19 : 18,
                                top: props.isMobile ? 12 : -1
                            }}/>}

                        />
                    </div>
                    <div className={s.autentication_item_description}>
                        When you log into your account from a new IP address, all withdrawals will be blocked for 24
                        hours, and you will receive an email notification.
                    </div>
                </div>

                <div className={s.buttons_block_end}>
                    <div className={s.button_wrapper}>
                        <MyBtn
                            title={"Save"}
                            isPersonalAccountBtn
                            large
                            localDisable={isButtonDisabled}
                            onClick={() => sendNewSettings()}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default WithdrawalSettings;
