import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./BankTransferFormPersonal.module.css";
import {Popover} from "antd";
import info from "../../assets/img/personalAccount/information.svg";
import CustomDropdown from "../ui/CustomDropdown/CustomDropdown";
import MyInput from "../ui/MyInput/MyInput";
import classnames from "classnames";
import MyBtn from "../ui/MyBtn/MyBtn";
import {Persist} from "formik-persist";
import {motion} from "framer-motion";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import CustomDropdownForAccounts from "../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import {ReactComponent as Plus} from "../../assets/img/personalAccount/plus.svg";
import {
    typesList,
} from "../../Constants/StaticData"
import {
    CurrencyHelper,
    PaymentTypeHelper,
    getValidationSchema
} from "./FormHelpers/FormHelpers";
import {setPaymentRegionFilter} from "../../store/reducers/TransactionsSlice/TransactionsSlice";
import {useSelector} from "react-redux";
import {selectAccountsByFilter} from "../../store/reducers/TransactionsSlice/selector";
import invoice from "../../assets/img/personalAccount/invoice.svg";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import attachedDoc from "../../assets/img/personalAccount/document.svg";
import delete_document from "../../assets/img/personalAccount/delete_doc.svg";

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>
    setTransferData?: Dispatch<SetStateAction<any>>
    id?: string | number;
}

const BankTransferFormPersonal = (props: PropsType) => {

    const [paymentRegion, setPaymentRegion] = useState("")
    const [currency, setCurrency] = useState('')
    const [isMobile, setIsMobile] = useState(false)
    const dispatch = useAppDispatch()
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)

    const accountsList = useSelector(selectAccountsByFilter)

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth])

    useEffect(() => {
        dispatch(setPaymentRegionFilter(paymentRegion))
        return () => {
            localStorage.removeItem('transfer-form')
        }
    }, [paymentRegion])

    return (
        <div>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >
                <Formik
                    initialValues={{
                        fullName: '',
                        recipientsIban: '',
                        recipientsSwiftBic: '',
                        reference: '',
                        recipientsAddress: '',
                        recipientsAccountNumber: '',
                        sortCode: '',
                        paymentRegions: '',
                        payerAccount: '',
                        amount: '',
                        templateName: '',
                        invoice: '',
                        type: 'international',
                    }}
                    validationSchema={getValidationSchema(paymentRegion, currency)}
                    onSubmit={(values) => {

                        let tempData = {
                            ...values,
                            transferCurrency: currency,
                            accountId: accountId
                        };

                        props.setTransferData && props.setTransferData(tempData)
                        props.setCurrent && props.setCurrent(1)
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          resetForm,
                          setFieldValue,
                          handleChange,
                          handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={s.row}>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Payer account
                                        <Popover content={"Please select the account you wish to make a transfer from."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <CustomDropdownForAccounts
                                        items={accountsList}
                                        placeholder={"Please select the account"}
                                        id="payerAccount"
                                        name="payerAccount"
                                        isError={errors.payerAccount}
                                        touched={touched.payerAccount}
                                        setCurrency={setCurrency}
                                    />
                                    {errors.payerAccount && touched.payerAccount &&
                                        <div className={s.error_message}>{errors.payerAccount}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Payment type
                                    </div>
                                    <CustomDropdown
                                        customId="paymentRegions"
                                        items={typesList}
                                        placeholder={"Please select the payment type"}
                                        id="paymentRegions"
                                        name="paymentRegions"
                                        isError={errors.paymentRegions}
                                        touched={touched.paymentRegions}
                                        setItem={setPaymentRegion}
                                    />
                                    {errors.paymentRegions && touched.paymentRegions &&
                                        <div className={s.error_message}>{errors.paymentRegions}</div>}
                                </div>

                            </div>

                            {/*Domestic (for EUR)*/}
                            {
                                values.paymentRegions === "domestic" && currency === "EUR" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Outgoing Payment Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Name
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName && touched.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN
                                                <Popover
                                                    content={"Enter the recipient’s bank account number or IBAN account."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsIban"}
                                                     name={"recipientsIban"}
                                                     touched={touched.recipientsIban}
                                                     value={values.recipientsIban}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsIban}
                                            />
                                            {errors.recipientsIban && touched.recipientsIban &&
                                                <div className={s.error_message}>{errors.recipientsIban}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BIC Code
                                                <Popover
                                                    content={"A SWIFT code is an international bank code that identifies " +
                                                        "particular banks worldwide. It's also known as a Bank Identifier" +
                                                        " Code (BIC). A SWIFT code consists of 8 or 11 characters."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsSwiftBic"}
                                                     name={"recipientsSwiftBic"}
                                                     touched={touched.recipientsSwiftBic}
                                                     value={values.recipientsSwiftBic}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsSwiftBic}
                                            />
                                            {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                        </div>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    * Amount
                                                    <Popover
                                                        content={"Select a currency and enter the transfer amount.\n" +
                                                            "In the account balance in the selected currency is insufficient, the system " +
                                                            "will automatically offer you to top up using funds in a different currency " +
                                                            "you have on the account."}
                                                        color={'#EEEFEF'}
                                                        overlayInnerStyle={{
                                                            fontFamily: 'Manrope',
                                                            fontSize: '14px',
                                                            width: '280px',
                                                            padding: '13px 16px'
                                                        }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"amount"}
                                                         name={"amount"}
                                                         touched={touched.amount}
                                                         value={values.amount}
                                                         onChange={handleChange}
                                                         isError={errors.amount}
                                                />
                                                {errors.amount && touched.amount &&
                                                    <div
                                                        className={classnames(s.error_message, s.error_amount)}>{errors.amount}</div>}
                                            </div>

                                            <div className={s.currency_dropdown}>
                                                <div className={s.input_label}>
                                                    Currency
                                                </div>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Reference
                                            </div>
                                            <MyInput id={"reference"}
                                                     name={"reference"}
                                                     touched={touched.reference}
                                                     value={values.reference}
                                                     onChange={handleChange}
                                                     isError={errors.reference}
                                            />
                                            {errors.reference && touched.reference &&
                                                <div className={s.error_message}>{errors.reference}</div>}
                                        </div>

                                        <div className={s.input_block}>

                                        </div>
                                    </div>
                                </div>
                            }

                            {/*International (for EUR)*/}
                            {
                                values.paymentRegions === "international" && currency === "EUR" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Outgoing Payment Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Name
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName && touched.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN
                                                <Popover
                                                    content={"Enter the recipient’s bank account number or IBAN account."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsIban"}
                                                     name={"recipientsIban"}
                                                     touched={touched.recipientsIban}
                                                     value={values.recipientsIban}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsIban}
                                            />
                                            {errors.recipientsIban && touched.recipientsIban &&
                                                <div className={s.error_message}>{errors.recipientsIban}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * SWIFT / BIC Code
                                                <Popover
                                                    content={"A SWIFT code is an international bank code that identifies " +
                                                        "particular banks worldwide. It's also known as a Bank Identifier" +
                                                        " Code (BIC). A SWIFT code consists of 8 or 11 characters."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsSwiftBic"}
                                                     name={"recipientsSwiftBic"}
                                                     touched={touched.recipientsSwiftBic}
                                                     value={values.recipientsSwiftBic}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsSwiftBic}
                                            />
                                            {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s address
                                            </div>
                                            <MyInput id={"recipientsAddress"}
                                                     name={"recipientsAddress"}
                                                     touched={touched.recipientsAddress}
                                                     value={values.recipientsAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAddress}
                                            />
                                            {errors.recipientsAddress && touched.recipientsAddress &&
                                                <div className={s.error_message}>{errors.recipientsAddress}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Reference
                                            </div>
                                            <MyInput id={"reference"}
                                                     name={"reference"}
                                                     touched={touched.reference}
                                                     value={values.reference}
                                                     onChange={handleChange}
                                                     isError={errors.reference}
                                            />
                                            {errors.reference && touched.reference &&
                                                <div className={s.error_message}>{errors.reference}</div>}
                                        </div>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    * Amount
                                                    <Popover
                                                        content={"Select a currency and enter the transfer amount.\n" +
                                                            "In the account balance in the selected currency is insufficient, the system " +
                                                            "will automatically offer you to top up using funds in a different currency " +
                                                            "you have on the account."}
                                                        color={'#EEEFEF'}
                                                        overlayInnerStyle={{
                                                            fontFamily: 'Manrope',
                                                            fontSize: '14px',
                                                            width: '280px',
                                                            padding: '13px 16px'
                                                        }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"amount"}
                                                         name={"amount"}
                                                         touched={touched.amount}
                                                         value={values.amount}
                                                         onChange={handleChange}
                                                         isError={errors.amount}
                                                />
                                                {errors.amount && touched.amount &&
                                                    <div
                                                        className={classnames(s.error_message, s.error_amount)}>{errors.amount}</div>}
                                            </div>

                                            <div className={s.currency_dropdown}>
                                                <div className={s.input_label}>
                                                    Currency
                                                </div>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*Domestic (for GBP)*/}
                            {
                                values.paymentRegions === "domestic" && currency === "GBP" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Outgoing Payment Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Name
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName && touched.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Account Number
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Sort Code
                                            </div>
                                            <MyInput id={"sortCode"}
                                                     name={"sortCode"}
                                                     touched={touched.sortCode}
                                                     value={values.sortCode}
                                                     onChange={handleChange}
                                                     isError={errors.sortCode}
                                            />
                                            {errors.sortCode && touched.sortCode &&
                                                <div className={s.error_message}>{errors.sortCode}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Reference
                                            </div>
                                            <MyInput id={"reference"}
                                                     name={"reference"}
                                                     touched={touched.reference}
                                                     value={values.reference}
                                                     onChange={handleChange}
                                                     isError={errors.reference}
                                            />
                                            {errors.reference && touched.reference &&
                                                <div className={s.error_message}>{errors.reference}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    * Amount
                                                    <Popover
                                                        content={"Select a currency and enter the transfer amount.\n" +
                                                            "In the account balance in the selected currency is insufficient, the system " +
                                                            "will automatically offer you to top up using funds in a different currency " +
                                                            "you have on the account."}
                                                        color={'#EEEFEF'}
                                                        overlayInnerStyle={{
                                                            fontFamily: 'Manrope',
                                                            fontSize: '14px',
                                                            width: '280px',
                                                            padding: '13px 16px'
                                                        }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"amount"}
                                                         name={"amount"}
                                                         touched={touched.amount}
                                                         value={values.amount}
                                                         onChange={handleChange}
                                                         isError={errors.amount}
                                                />
                                                {errors.amount && touched.amount &&
                                                    <div
                                                        className={classnames(s.error_message, s.error_amount)}>{errors.amount}</div>}
                                            </div>

                                            <div className={s.currency_dropdown}>
                                                <div className={s.input_label}>
                                                    Currency
                                                </div>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*International (for GBP)*/}
                            {
                                values.paymentRegions === "international" && currency === "GBP" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Outgoing Payment Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Name
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName && touched.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN
                                                <Popover
                                                    content={"Enter the recipient’s bank account number or IBAN account."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsIban"}
                                                     name={"recipientsIban"}
                                                     touched={touched.recipientsIban}
                                                     value={values.recipientsIban}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsIban}
                                            />
                                            {errors.recipientsIban && touched.recipientsIban &&
                                                <div className={s.error_message}>{errors.recipientsIban}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * SWIFT / BIC Code
                                                <Popover
                                                    content={"A SWIFT code is an international bank code that identifies " +
                                                        "particular banks worldwide. It's also known as a Bank Identifier" +
                                                        " Code (BIC). A SWIFT code consists of 8 or 11 characters."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsSwiftBic"}
                                                     name={"recipientsSwiftBic"}
                                                     touched={touched.recipientsSwiftBic}
                                                     value={values.recipientsSwiftBic}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsSwiftBic}
                                            />
                                            {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s address
                                            </div>
                                            <MyInput id={"recipientsAddress"}
                                                     name={"recipientsAddress"}
                                                     touched={touched.recipientsAddress}
                                                     value={values.recipientsAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAddress}
                                            />
                                            {errors.recipientsAddress && touched.recipientsAddress &&
                                                <div className={s.error_message}>{errors.recipientsAddress}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Reference
                                            </div>
                                            <MyInput id={"reference"}
                                                     name={"reference"}
                                                     touched={touched.reference}
                                                     value={values.reference}
                                                     onChange={handleChange}
                                                     isError={errors.reference}
                                            />
                                            {errors.reference && touched.reference &&
                                                <div className={s.error_message}>{errors.reference}</div>}
                                        </div>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    * Amount
                                                    <Popover
                                                        content={"Select a currency and enter the transfer amount.\n" +
                                                            "In the account balance in the selected currency is insufficient, the system " +
                                                            "will automatically offer you to top up using funds in a different currency " +
                                                            "you have on the account."}
                                                        color={'#EEEFEF'}
                                                        overlayInnerStyle={{
                                                            fontFamily: 'Manrope',
                                                            fontSize: '14px',
                                                            width: '280px',
                                                            padding: '13px 16px'
                                                        }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"amount"}
                                                         name={"amount"}
                                                         touched={touched.amount}
                                                         value={values.amount}
                                                         onChange={handleChange}
                                                         isError={errors.amount}
                                                />
                                                {errors.amount && touched.amount &&
                                                    <div
                                                        className={classnames(s.error_message, s.error_amount)}>{errors.amount}</div>}
                                            </div>

                                            <div className={s.currency_dropdown}>
                                                <div className={s.input_label}>
                                                    Currency
                                                </div>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {!props.id && <div className={s.block}>
                                <div className={s.title}>
                                    Save the Transfer Details as a Template
                                </div>

                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            template Name
                                            <Popover
                                                content={"In order to save this transfer as a template, please enter the " +
                                                    "template name."}
                                                color={'#EEEFEF'}
                                                overlayInnerStyle={{
                                                    fontFamily: 'Inter',
                                                    width: '280px',
                                                    padding: '13px 16px'
                                                }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <MyInput id={"templateName"}
                                                 name={"templateName"}
                                                 touched={touched.templateName}
                                                 value={values.templateName}
                                                 onChange={handleChange}
                                        />
                                        {errors.templateName && touched.templateName &&
                                            <div className={s.error_message}>{errors.templateName}</div>}
                                    </div>

                                    <div className={s.input_block}>
                                    </div>
                                </div>
                            </div>}

                            {
                                !props.id &&
                                <div>
                                    <div className={classnames(s.row_doc, s.flex_start)}>
                                        <div className={s.invoice_img}>
                                            <img src={invoice} alt=""/>
                                        </div>
                                        <div>
                                            <p className={s.invoice_title}>Add an invoice (optional)</p>
                                            <p className={s.invoice_desc}>Appropriate format: JPEG, JPG, PNG, TXT,
                                                PDF.</p>
                                        </div>
                                    </div>
                                    <div className={s.attached_file_wrapper}>
                                        <div className={s.button_wrapper}>
                                            <TransparentButton
                                                isPersonalAccountBtn
                                                title={"Add file"}
                                                small
                                                icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                                onClick={(e: any) => {
                                                    e.preventDefault()
                                                    // @ts-ignore
                                                    document.getElementById('hiddenInvoice').click()
                                                }}
                                            />
                                            <input type="file"
                                                   accept=".png, .jpg, .jpeg, .pdf, .txt"
                                                   id={"hiddenInvoice"}
                                                   className={s.hidden_input}
                                                   onChange={(event) => {
                                                       // @ts-ignore
                                                       setFieldValue("invoice", event.currentTarget.files[0]);
                                                   }}
                                            />
                                        </div>
                                        <div className={s.attached_file}>
                                            {
                                                values.invoice && <img src={attachedDoc} alt=""/>
                                            }
                                            {
                                                // @ts-ignore
                                                <span className={s.invoice_doc_name}>{values?.invoice?.name}</span>
                                            }
                                            {
                                                // @ts-ignore
                                                values?.invoice?.name &&
                                                <img onClick={() => {
                                                    // @ts-ignore
                                                    document.getElementById("hiddenInvoice").value = ""
                                                    setFieldValue("invoice", "")
                                                }}
                                                     className={s.delete_doc}
                                                     src={delete_document}
                                                     alt=""
                                                />
                                            }
                                        </div>
                                    </div>


                                </div>
                            }

                            <div className={s.continue_block}>
                                <div className={s.required}>
                                    <div>
                                        * required fields.
                                    </div>
                                </div>
                                {!props.id && <div className={s.button_wrapper}>
                                    <MyBtn title={"Continue"} large type={"submit"} isPersonalAccountBtn/>
                                </div>}
                            </div>

                            <Persist name="transfer-form"/>

                            <CurrencyHelper
                                accountsList={accountsList}
                                payerAccount={values.payerAccount}
                                setCurrency={setCurrency}
                            />

                            <PaymentTypeHelper name={"paymentType"}/>

                        </form>
                    )}
                </Formik>
            </motion.div>
        </div>
    );
};

export default BankTransferFormPersonal;
