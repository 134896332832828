import React, {useState} from 'react';
import s from "../BankTransfer/BankTransfer.module.css";
import ToAnLuxPayStepOne from "./ToAnLuxPayStepOne/ToAnLuxPayStepOne";
import ToAnLuxPayStepTwo from "./ToAnLuxPayStepTwo/ToAnLuxPayStepTwo";

const ToAnLuxPay = () => {
    const [current, setCurrent] = useState(0);
    const [ourTransferData, setOurTransferData] = useState({})

    const steps = [
        {
            content: <ToAnLuxPayStepOne setCurrent={setCurrent}
                                        setTransferData={setOurTransferData}
            />
        },
        {
            content: <ToAnLuxPayStepTwo setCurrent={setCurrent}
                                        current={current}
                                        transferData={ourTransferData}
            />
        },
    ];

    return (
        <>
            <div className={s.wrapper}>
                {steps[current].content}
            </div>
            <div className={s.transfer_description}>
                <p>
                    Internal transfers are possible in any currency. All internal transfers reach the recipient within a
                    minute.
                </p>
                <p>
                    If the specified recipient is incorrect, the money will automatically be refunded to your account
                    within 30 days or, in case you initiate a refund, in 3 days.
                </p>
            </div>
        </>

    );
};

export default ToAnLuxPay;
