import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import s from '../../../../styles/styles.module.css'
import KycPersonal from "./KycPersonal/KycPersonal";
import {motion} from 'framer-motion';
import ProfileSettings from "./ProfileSettings/ProfileSettings";
import Limits from "./Limits/Limits";
import { useAppSelector } from "../../../../hooks/redux";

const Settings = () => {

    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus)

    const kycIsValid = () => {
        if (!kycStatus) {
            return false
        } else if (kycStatus === "New") {
            return false
        } else if (kycStatus === "Rejected") {
            return false
        } else {
            return true
        }
    }
    
    return (
        <div style={{height: '100%'}}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >
                <Tabs>
                    <TabList className={s.tabstitle_block}>
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Profile Settings</Tab>
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>KYC</Tab>
                        {kycIsValid() && <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Limits</Tab>}
                    </TabList>


                    <TabPanel>
                        <ProfileSettings/>
                    </TabPanel>
                    <TabPanel>
                        <KycPersonal/>
                    </TabPanel>
                    {
                        kycIsValid() &&
                        <TabPanel>
                            <Limits/>
                        </TabPanel>
                    }
                </Tabs>
            </motion.div>
        </div>
    );
};

export default Settings;
