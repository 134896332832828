import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectAccountsByFilter, selectTopUpByAccount} from "../../../../../store/reducers/TransactionsSlice/selector";
import {useAppDispatch} from "../../../../../hooks/redux";
import {setFilters, setFiltersTemplates} from "../../../../../store/reducers/TransactionsSlice/TransactionsSlice";
import s from "./DetailsForBankTransfer.module.css";
import {Formik} from "formik";
import {Popover} from "antd";
import infoIcon from "../../../../../assets/img/personalAccount/information.svg";
import CustomDropdownForAccounts
    from "../../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import CustomDropdown from "../../../../../components/ui/CustomDropdown/CustomDropdown";
import {typesList} from "../../../../../Constants/StaticData";

interface Helper {
    transferValues: {
        accountNumber: string;
    };
}

const DetailsForBankTransferHelper: React.FC<Helper> = ({ transferValues }) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (transferValues.accountNumber) {
            dispatch(setFilters({ accountNumber: transferValues.accountNumber }));
        }
    }, [dispatch, transferValues.accountNumber]);

    return null;
}

const DetailsForBankTransfer = () => {
    const accountsList = useSelector(selectAccountsByFilter)
    const accountTopUpList = useSelector(selectTopUpByAccount)
    const dispatch = useAppDispatch()
    const [currency, setCurrency] = useState("")

    const clearFilters = () => {
        dispatch(setFiltersTemplates(
            {
                accountNumber: '',
                period: null,
                from: '',
                to: '',
                purpose: '',
                recipient: '',
                amount: '',
                incoming: false,
                outgoing: false,
                currency: '',
                selectedType: '',
                templateName: '',
                paymentRegion: ''
            }
        ))
    }

    useEffect(() => {
        return clearFilters()
    }, [accountTopUpList])

    return (
        <div className={s.accountTopUpWrapper}>
            <Formik initialValues={{
                accountNumber: '',
                paymentType: '',
            }}
                    onSubmit={(values) => {
                        dispatch(setFilters({accountNumber: values.accountNumber}))
                    }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <DetailsForBankTransferHelper transferValues={values} />
                        <div className={s.detailsWrapper}>
                            <p className={s.detailsTitle}>Details for the Bank Transfer</p>
                            <div className={s.form_row}>
                                <div className={s.input_block}>
                                    <p className={s.labelText}>Payer account <Popover
                                        content={"Please select the account you wish to make a transfer from."}
                                        color={'#EEEFEF'}
                                        placement={"bottom"}
                                        overlayInnerStyle={{
                                            fontFamily: 'Manrope',
                                            padding: '13px 16px',
                                            backgroundColor: '#EEEFEF',
                                            boxShadow: 'none'
                                        }}
                                    >
                                        <img src={infoIcon} alt=""/>
                                    </Popover></p>
                                    <CustomDropdownForAccounts
                                        setCurrency={setCurrency}
                                        items={accountsList}
                                        id='accountNumber'
                                        name='accountNumber'
                                        placeholder={"Please select the account"}
                                    />
                                </div>
                                <div className={s.input_block}>
                                    <p className={s.labelText}>payment type</p>
                                    <div className={s.dropdown_wrapper}>
                                        <CustomDropdown
                                            items={typesList}
                                            id='paymentType'
                                            name='paymentType'
                                            placeholder={"Please select the payment type"}
                                        />
                                    </div>
                                </div>
                            </div>

                            { currency === "GBP" && values.paymentType === "Domestic"
                                ? <div className={s.data_wrapper}>
                                    <div className={s.row}>
                                        <span>Beneficiary’s Name</span>
                                        <span><b>
                                            {/* {accountTopUpList.domestic.map((e: any) => e.beneficiaryName)} */}
                                        </b></span>
                                    </div>
                                    <div className={s.row}>
                                        <span>Beneficiary’s Bank Name</span>
                                        <span><b>
                                            {/* {accountTopUpList.domestic.map((e: any) => e.bankName)} */}
                                        </b></span>
                                    </div>

                                    {/* TO DO */}
                                    <div className={s.row}>
                                        <span>Beneficiary’s Account Number</span>
                                        <span><b>
                                            {/* {accountTopUpList.domestic.map((e: any) => e.accountNumber)} */}
                                        </b></span>
                                    </div>
                                    {/* TO DO */}

                                    <div className={s.row}>
                                        <span>Beneficiary’s Sort Code</span>
                                        <span><b>
                                            {/* {accountTopUpList.domestic.map((e: any) => e.sortCode)} */}
                                        </b></span>
                                    </div>
                                    <div className={s.row}>
                                        <span>Reference</span>
                                        <span><b>
                                            {/* {accountTopUpList.domestic.map((e: any) => e.reference)} */}
                                        </b></span>
                                    </div>
                                </div>
                                : <div className={s.data_wrapper}>
                                    <div className={s.row}>
                                        <span>Beneficiary’s Name</span>
                                        <span><b>
                                            {/* {accountTopUpList.international.map((e: any) => e.beneficiaryName)} */}
                                        </b></span>
                                    </div>

                                    {/* TO DO */}
                                    <div className={s.row}>
                                        <span>Beneficiary’s BIC Code</span>
                                        <span><b>
                                            {/* {accountTopUpList.international.map((e: any) => e.bicCode)} */}
                                        </b></span>
                                    </div>
                                    {/* TO DO */}

                                    <div className={s.row}>
                                        <span>Beneficiary’s IBAN</span>
                                        <span><b>
                                            {/* {accountTopUpList.international.map((e: any) => e.iban)} */}
                                        </b></span>
                                    </div>
                                    <div className={s.row}>
                                        <span>Beneficiary’s Bank Name</span>
                                        <span><b>
                                            {/* {accountTopUpList.international.map((e: any) => e.bankName)} */}
                                        </b></span>
                                    </div>
                                    <div className={s.row}>
                                        <span>Beneficiary’s Bank address</span>
                                        <span><b>
                                            {/* {accountTopUpList.international.map((e: any) => e.bankAddress)} */}
                                        </b></span>
                                    </div>
                                    <div className={s.row}>
                                        <span>Mandatory Reference</span>
                                        <span><b>
                                            {/* {accountTopUpList.international.map((e: any) => e.reference)} */}
                                        </b></span>
                                    </div>
                                </div>
                            }
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
};

export default DetailsForBankTransfer;
