import React, {useEffect} from 'react';
import s from './Accounts.module.css'
import styles from "../../../styles/styles.module.css"
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {AccountStatement} from "./AccountStatement/AccountStatement";
import {GetTransactionsListThunk} from "../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {AccountTopUp} from "./AccountTopUp/AccountTopUp";
import {AccountOverview} from './AccountOverview/AccountOverview';
import {motion} from "framer-motion";

const Accounts = () => {
    const dispatch = useAppDispatch()

    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)

    useEffect(() => {
        dispatch(GetTransactionsListThunk(token, accountId))
    }, [])

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.container}>
                <Tabs>
                    <TabList className={styles.tabstitle_block}>
                        <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Account Overview</Tab>
                        <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Statements</Tab>
                        <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Account Top-Up</Tab>
                    </TabList>
                    <TabPanel>
                        <AccountOverview/>
                    </TabPanel>
                    <TabPanel>
                        <AccountStatement/>
                    </TabPanel>
                    <TabPanel>
                        <AccountTopUp/>
                    </TabPanel>
                </Tabs>
            </div>
        </motion.div>
    );
};

export default Accounts;
