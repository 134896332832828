import React, {useEffect, useState} from 'react';
import s from "./CustomDropdown.module.css";
import selectArrow from '../../../assets/img/select_arrow.svg'
import classnames from "classnames";
import {useField, useFormikContext} from "formik";

type PropsType = {
    items: string[]
    isError?: string | undefined
    setCountryError?: any
    placeholder?: string
    id: string
    name?: any
    touched?: any
    customId?: string

    disable?: boolean
    setItem?: any
    onChange?: (selectedValue: string) => void;
    resetAnotherField?: any
}

const CustomDropdown = (props: PropsType) => {

    const [isDropdownVisible, setIsDropdownVisible] = useState(false)

    const {setFieldValue} = useFormikContext();
    const [field] = useField<string>(props.name);


    useEffect(() => {
        // Trigger the onChange prop when the selected value changes
        if (props.onChange) {
            props.onChange(field.value);
        }
    }, [field.value, props.onChange]);

    if (isDropdownVisible) {
        const myPopup: any = document.querySelector(`#${props.id}`);
        document.addEventListener('click', (e) => {
            const withinBoundaries = e.composedPath().includes(myPopup);

            if (!isDropdownVisible) return

            if (!withinBoundaries) {
                setIsDropdownVisible(false)
            }
        })
    }

    return (
        <div className={s.phoneInputWrapper} id={props.id}>
            <div
                className={classnames(s.mySelect)}
                onClick={(e) => {
                    if (props.disable) {
                        return
                    }
                    e.stopPropagation()
                    setIsDropdownVisible((prevState) => !prevState);
                }}
            >
                <div className={s.selectedBlock}>
                    <img
                        className={isDropdownVisible ? s.arrowReverse : s.arrow}
                        width={9}
                        height={5}
                        src={selectArrow}
                        alt=""
                    />
                </div>
                <div className={isDropdownVisible ? s.optionsWrapper : s.none}>
                    {props.items.map((item, index) => (
                        <div
                            className={s.option}
                            key={index}
                            onClick={() => {

                                if (props.resetAnotherField) {
                                    setFieldValue('paymentTypeByRegionForm', '');
                                }

                                if (props.name != null) {
                                    setFieldValue(props.name, item);
                                    props.setItem(item);
                                }
                            }}
                        >
                            <div className={s.countryName}>{item}</div>
                        </div>
                    ))}
                </div>
            </div>
            <input
                className={classnames(
                    s.my_input,
                    props.isError && props.touched && s.error,
                    props.disable && s.disableInput
                )}
                id={props.id}
                name={props.name}
                type="text"
                value={field.value}
                placeholder={props.placeholder}
            />
        </div>
    );
};

export default CustomDropdown;
