import React, {useEffect, useState} from 'react';
import s from './Limits.module.css'
import {motion} from 'framer-motion';
import TransparentButton from "../../../../../components/ui/TransparentButton/TransparentButton";
import {Progress, ConfigProvider} from 'antd';
import {Formik} from "formik";
import classnames from "classnames";
import styles from "../../../../../styles/styles.module.css";
import info_img from "../../../../../assets/img/personalAccount/info-circle.svg";
import CustomModal from "../../../../../components/ui/CustomModal/CustomModal";
import MyInput from "../../../../../components/ui/MyInput/MyInput";
import invoice from "../../../../../assets/img/personalAccount/invoice.svg";
import Icon from "@ant-design/icons";
import attachedDoc from "../../../../../assets/img/personalAccount/document.svg";
import delete_document from "../../../../../assets/img/personalAccount/delete_doc.svg";
import {ReactComponent as Plus} from "../../../../../assets/img/personalAccount/plus.svg";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";

const Limits = () => {

    const [isMobile, setIsMobile] = useState(false)
    const [isIncreaseLimitPopupOpen, setIsIncreaseLimitPopupOpen] = useState(false)
    const [limitType, setLimitType] = useState('')

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth])

    const startIncreaseLimit = (type: string) => {
        if (type === "daily") {
            setLimitType("daily")
        } else {
            setLimitType(type)
        }

        setIsIncreaseLimitPopupOpen(true)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModal type={'info'} isModalOpen={isIncreaseLimitPopupOpen}>
                <Formik
                    initialValues={{
                        amount: "",
                        reason: "",
                        invoice: "",
                    }}
                    validationSchema={""}
                    onSubmit={(values) => {

                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          resetForm,
                          setFieldValue,
                          handleChange,
                          handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={classnames(styles.modal_content, s.modal_content)}>
                                <img className={s.info_img} src={info_img} alt=""/>
                                <h4 className={s.popup_title}>Send request to increase {limitType} limit</h4>
                                <div className={s.input_block}>
                                    <span className={s.input_label}>*enter amount</span>
                                    <MyInput
                                        name={"amount"}
                                        id={"amount"}
                                        value={values.amount}
                                        onChange={handleChange}
                                        isError={errors.amount}
                                    />
                                    {errors.amount &&
                                        <div className={s.input_error}>
                                            {errors.amount}
                                        </div>
                                    }
                                </div>

                                <div className={s.input_block}>
                                    <span className={s.input_label}>*enter amount</span>
                                    <MyInput
                                        name={"reason"}
                                        id={"reason"}
                                        value={values.reason}
                                        onChange={handleChange}
                                        isError={errors.reason}
                                    />
                                    {errors.reason &&
                                        <div className={s.input_error}>
                                            {errors.reason}
                                        </div>
                                    }
                                </div>

                                <div className={s.add_dock_block}>
                                    <div className={classnames(s.row_doc, s.flex_start)}>
                                        <div className={s.invoice_img}>
                                            <img src={invoice} alt=""/>
                                        </div>
                                        <div>
                                            <p className={s.invoice_title}>Add an invoice (optional)</p>
                                            <p className={s.invoice_desc}>Appropriate format: JPEG, JPG, PNG, TXT,
                                                PDF.</p>
                                        </div>
                                    </div>
                                    <div className={s.attached_file_wrapper}>
                                        <div className={s.button_wrapper_add}>
                                            <TransparentButton
                                                isPersonalAccountBtn
                                                title={"Add file"}
                                                small
                                                icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                                onClick={(e: any) => {
                                                    e.preventDefault()
                                                    // @ts-ignore
                                                    document.getElementById('hiddenInvoice').click()
                                                }}
                                            />
                                            <input type="file"
                                                   accept=".png, .jpg, .jpeg, .pdf, .txt"
                                                   id={"hiddenInvoice"}
                                                   className={s.hidden_input}
                                                   onChange={(event) => {
                                                       // @ts-ignore
                                                       setFieldValue("invoice", event.currentTarget.files[0])
                                                           .then(r => {
                                                           });
                                                   }}
                                            />
                                        </div>
                                        <div className={s.attached_file}>
                                            {
                                                values.invoice && <img src={attachedDoc} alt=""/>
                                            }
                                            {
                                                // @ts-ignore
                                                <span className={s.invoice_doc_name}>{values?.invoice?.name}</span>
                                            }
                                            {
                                                // @ts-ignore
                                                values?.invoice?.name &&
                                                <img onClick={() => {
                                                    // @ts-ignore
                                                    document.getElementById("hiddenInvoice").value = ""
                                                    setFieldValue("invoice", "")
                                                        .then(r => {
                                                        })
                                                }}
                                                     className={s.delete_doc}
                                                     src={delete_document}
                                                     alt=""
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={s.button_row}>
                                    <div className={s.button_wrapper}>
                                        <TransparentButton
                                            title={"Cancel"}
                                            isPersonalAccountBtn
                                            medium
                                            buttonType={"info"}
                                            onClick={() => setIsIncreaseLimitPopupOpen(false)}
                                        />
                                    </div>

                                    <div className={s.button_wrapper}>
                                        <MyBtn
                                            title={"Send"}
                                            isPersonalAccountBtn
                                            buttonType={"info"}
                                        />
                                    </div>
                                </div>

                            </div>
                        </form>
                    )}
                </Formik>
            </CustomModal>


            <div className={s.wrapper}>
                <div className={s.limit_block}>
                    <div className={s.title}>
                        Daily Limit: Amount 500
                    </div>
                    <div className={s.line}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Progress: {
                                        lineBorderRadius: 5,
                                    },
                                },
                            }}
                        >
                            <Progress
                                percent={70}
                                showInfo={false}
                                size={isMobile ? ['100%', 32] : [656, 48]}
                                strokeColor={'#398361'}
                                trailColor={'#E7E7E7'}
                            />
                        </ConfigProvider>
                        <div className={s.line_value}>
                            402/500
                        </div>
                    </div>
                    <div className={s.button_block}>
                        <TransparentButton
                            title={"Send request to increase limit"}
                            medium={isMobile}
                            large={!isMobile}
                            isPersonalAccountBtn
                            onClick={() => startIncreaseLimit("daily")}
                        />
                    </div>
                </div>

                <div className={s.limit_block}>
                    <div className={s.title}>
                        Monthly Limit: Amount 15 000
                    </div>
                    <div className={s.line}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Progress: {
                                        lineBorderRadius: 5,
                                    },
                                },
                            }}
                        >
                            <Progress
                                percent={55}
                                showInfo={false}
                                size={isMobile ? ['100%', 32] : [656, 48]}
                                strokeColor={'#398361'}
                                trailColor={'#E7E7E7'}
                            />
                        </ConfigProvider>
                        <div className={s.line_value}>
                            8 570/15 000
                        </div>
                    </div>
                    <div className={s.button_block}>
                        <TransparentButton
                            title={"Send request to increase limit"}
                            medium={isMobile}
                            large={!isMobile}
                            isPersonalAccountBtn
                            onClick={() => startIncreaseLimit("monthly")}
                        />
                    </div>
                </div>
            </div>

        </motion.div>
    );
};

export default Limits;
