import * as yup from "yup";

const regex = /^[A-Za-z0-9 ().\-/+<&$*%;@="\\\s]+$/;

const regexDomesticGBP = /^[a-zA-Z0-9\/\-?:().,’+\s#=!"%&*<>;{@\r\n]*$/

export const validationSchemaDomesticEUR  = {
    payerAccount: yup.string().required('Required field').matches(regex, "wrong symbol") ,
    paymentRegions: yup.string().required('Required field').matches(regex, "wrong symbol"),
    fullName: yup.string().required('Required field').matches(regex, "wrong symbol"),
    recipientsIban: yup.string().required('Required field').matches(regex, "wrong symbol"),
    recipientsSwiftBic: yup.string().required('Required field').matches(regex, "wrong symbol"),
    reference: yup.string().required('Required field').matches(regex, "wrong symbol"),
    templateName: yup.string().max(100, 'max 100 symbols'),
};
export const validationSchemaInternationalEUR  = {
    fullName: yup.string().required('Required field').matches(regex, "wrong symbol"),
    recipientsIban: yup.string().required('Required field').matches(regex, "wrong symbol"),
    recipientsSwiftBic: yup.string().required('Required field').matches(regex, "wrong symbol"),
    recipientsAddress: yup.string().required('Required field').matches(regex, "wrong symbol"),
    reference: yup.string().required('Required field').matches(regex, "wrong symbol"),
    payerAccount: yup.string().required('Required field').matches(regex, "wrong symbol"),
    paymentRegions: yup.string().required('Required field').matches(regex, "wrong symbol"),
    templateName: yup.string().max(100, 'max 100 symbols').nullable().optional(),
};
export const validationSchemaDomesticGBP  = {
    payerAccount: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol"),
    fullName: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol"),
    reference: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol"),
    recipientsAccountNumber: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol"),
    sortCode: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol"),
    paymentRegions: yup.string().required('Required field').matches(regexDomesticGBP, "wrong symbol"),
    templateName: yup.string().max(100, 'max 100 symbols').matches(regexDomesticGBP, "wrong symbol"),
};
export const validationSchemaInternationalGBP  = {
    payerAccount: yup.string().required('Required field').matches(regex, "wrong symbol"),
    recipientsIban: yup.string().required('Required field').matches(regex, "wrong symbol"),
    recipientsSwiftBic: yup.string().required('Required field').matches(regex, "wrong symbol"),
    fullName: yup.string().required('Required field').matches(regex, "wrong symbol"),
    reference: yup.string().required('Required field').matches(regex, "wrong symbol"),
    recipientsAddress: yup.string().required('Required field').matches(regex, "wrong symbol"),
    paymentRegions: yup.string().required('Required field').matches(regex, "wrong symbol"),
    templateName: yup.string().max(100, 'max 100 symbols').matches(regex, "wrong symbol"),
};

export const defaultValidation  = yup.object({
    payerAccount: yup.string().required('Required field'),
    paymentRegions: yup.string().required('Required field'),
});
